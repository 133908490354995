<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
        <c-tree-table
          ref="typeTable"
          title="설비유형 목록"
          parentProperty="upEquipmentTypeCd"
          customID="equipmentTypeCd"
          :columns="grid1.columns"
          :data="grid1.data"
          :columnSetting="false"
          :isFullScreen="false"
          :isExcelDown="false"
          :usePaging="false"
          :expandAll="true"       
          :gridHeight="grid1.height"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="검색" icon="search" @btnClicked="getList" />
            </q-btn-group>
          </template>
        </c-tree-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
        <c-table
          ref="itemTable"
          title="부품별 일상점검항목"
          :columns="grid2.columns"
          :data="grid2.data"
          :merge="grid2.merge"
          :gridHeight="grid2.height"
          :filtering="false"
          selection="multiple"
          :usePaging="false"
          :editable="editable"
          :isExcelDown="false"
          :columnSetting="false"
          :expandAll="true"
          rowKey="checkTypeItemId"
          :hideBottom="true"
        >
          <template slot="table-chip">
            <q-btn-group outline >
              <q-chip v-if="rowTypeName">
                  <q-avatar icon="push_pin" color="green" text-color="white" /> 
                  {{ "설비유형 : " + rowTypeName}}
              </q-chip>
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'checkItemPartName'">
              {{ props.row.checkItemPartName }}
              <span>
                <div v-if="editable">
                  <q-btn-group outline class="ColumInnerBtnGroup">
                    <template v-for="(btn, idx) in col.btns">
                      <q-btn
                        :key="idx"
                        :label="btn.label"
                        :icon="btn.icon ? btn.icon : void 0"
                        :color="btn.color ? btn.color : 'blue-grey-4'"
                        :text-color="btn.textColor ? btn.textColor : 'white'"
                        class="ColumInnerBtn"
                        align="center"
                        @click.stop="innerBtnClicked(col, props, btn)">
                        <q-tooltip v-if="btn.tooltip">
                          <span v-html="btn.tooltip" />
                        </q-tooltip>
                      </q-btn>
                    </template>
                  </q-btn-group>
                </div>
              </span>
            </template>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && updateMode" label="부품추가" icon="add" :showLoading="false" @btnClicked="addrow" />
              <c-btn v-if="editable && updateMode && grid2.data.length > 0" :showLoading="false" label="삭제" icon="remove" @btnClicked="remove"/>
              <c-btn
                v-if="editable && updateMode && grid2.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave"
                :param="grid2.data"
                mappingType="POST"
                label="저장"
                icon="save"
                @beforeAction="saveClass"
                @btnCallback="saveCallback" />
            </q-btn-group>
          </template>
        </c-table> 
      </div>
    </div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">부품명</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="hiddenMstCd" autofocus @keyup.enter="setMst" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="취소" @click="cancelMst" />
          <q-btn flat label="추가" @click="setMst" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default 
  {
  name: 'equipment-daily-item',
  data() {
    return {
      prompt: false,
      hiddenMstCd: '',
      grid1: {
        columns: [
          {
            name: 'equipmentTypeName',
            field: 'equipmentTypeName',
            label: '설비유형',
            align: 'left',
            style: 'width:300px',
            sortable: false,
          },
        ],
        data: [],
        height: '720px'
      },
      grid2: {
        columns: [],
        height: '720px',
        data: [],
      },
      grid3: {
        columns: [],
        height: '300px',
        data: [],
      },
      grid4: {
        columns: [],
        height: '300px',
        data: [],
      },
      grid5: {
        columns: [],
        height: '400px',
        data: [],
      },
      searchParam: {
        plantCd: null,
        useFlag: 'Y',
        dailyFlag: 'Y',
      },
      equipClassInsItemData: {
        equipmentType: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
        checkItemNo: '',
        checkItemNm: '',
        checkMethodCd: null,
        decisionBasis: '',
        checkCaution: '',
      },
      editable: true,
      listUrl: '',
      itemDetailUrl: '',
      deleteUrl: '',
      saveUrl: '',
      updateUrl: '',
      checkUrl: '',
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      checkMethodItems: [],
      isSave: false,
      isSave3: false,
      isSave4: false,
      isSave5: false,
      isDelete: false,
      isDelete3: false,
      isDelete4: false,
      isDelete5: false,
      updateMode: false,
      saveType: 'POST',
      removeMode: false,
      key: {
        equipmentTypeCd: '',
        plantCd: '',
        equipmentCheckTypeCd: null,
      },
      rowTypeName: '',
    };
  },
  watch: {

  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.equipment.class.list.url;
      this.itemDetailUrl = selectConfig.mdm.equipment.class.item.daily.list.url;
      this.saveUrl = transactionConfig.mdm.equipment.class.item.daily.save.url;
      this.deleteUrl = transactionConfig.mdm.equipment.class.item.daily.delete.url;

      this.$comm.getComboItems('MDM_CHECK_CYCLE_CD').then(_result => {
        this.grid2.columns = [
          {
            required: true,
            name: 'checkItemPartName',
            field: 'checkItemPartName',
            label: '부품',
            btns: [
              { label: '', icon: 'add', color: 'orange', tooltip: '부품별 점검항목 추가' },
            ],
            type: 'custom',
            style: 'width:150px',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목',
            align: 'left',
            type: 'text',
            style: 'width:180px',
            sortable: false,
          },
          {
            name: 'checkItemMethod',
            field: 'checkItemMethod',
            label: '점검방법',
            style: 'width:180px',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            name: 'checkStandard',
            field: 'checkStandard',
            label: '점검기준',
            style: 'width:180px',
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            required: true,
            name: 'checkItemCycleCd',
            field: 'checkItemCycleCd',
            label: '주기',
            align: 'center',
            style: 'width:70px',
            type: 'select',
            comboItems: _result,
            sortable: false,
          },  
          {
            name: 'cbmTypeCd',
            field: 'cbmTypeCd',
            label: 'CBM<br>종류',
            align: 'center',
            style: 'width:80px',
            type: 'select',
            codeGroupCd: 'CBM_TYPE_CD',
            sortable: false
          },
          {
            name: 'gaugeWarnLcl',
            field: 'gaugeWarnLcl',
            label: '경고<br>하한값',
            align: 'left',
            type: 'text',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'gaugeWarnUcl',
            field: 'gaugeWarnUcl',
            label: '경고<br>상한값',
            align: 'left',
            type: 'text',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'gaugeExecLcl',
            field: 'gaugeExecLcl',
            label: '실행<br>하한값',
            align: 'left',
            type: 'text',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'gaugeExecUcl',
            field: 'gaugeExecUcl',
            label: '실행<br>상한값',
            align: 'left',
            type: 'text',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'cbmFlag',
            field: 'cbmFlag',
            label: 'CBM<br>여부',
            align: 'center',
            style: 'width:50px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ]
        this.grid2.merge =  [
          { index: 0, colName: "checkItemPartName" },
        ]
      })
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
        this.updateMode = false;
      },);
    },
    rowClick(row) {
      if (row) {
        this.rowTypeName = this.$_.clone(row.equipmentTypeName);
        this.key.equipmentTypeCd = this.$_.clone(row.equipmentTypeCd);
        this.key.plantCd = this.$_.clone(row.plantCd);
      }
      this.updateMode = true;
      // 설비유형별 점검항목 조회      
      this.$http.param = {checkItemPartCd: 'CIPC000001'}; // 설비부품별 점검
      this.$http.url = this.$format(this.itemDetailUrl, this.key.equipmentTypeCd, this.key.plantCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grid2.data = this.$_.clone(_result.data);
      },);
    },
    addrow() {
      if (!this.key.equipmentTypeCd) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '설비유형을 선택하세요.', // 설비유형을 선택하세요.
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      this.prompt = true;
    },
    remove() {
      let selectData = this.$refs['itemTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '하나 이상 선택하세요.', // 하나 이상 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid2.data = this.$_.reject(this.grid2.data, { checkTypeItemId: item.checkTypeItemId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.rowClick(null);
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveClass() {
      if (this.$comm.validTable(this.grid2.columns, this.grid2.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.rowClick({ plantCd: result.data.plantCd, equipmentTypeCd: result.data.equipmentTypeCd, equipmentTypeName: this.rowTypeName })
    },
    datachange(props) {
      if (props.row['editFlag'] !== 'C') {
        props.row['editFlag'] = 'U'
        props.row['chgUserId'] = this.$store.getters.user.userId
      }
    },
    innerBtnClicked(col, props) {
      this.grid2.data.splice(props.rowIndex+1, 0, {
        equipmentTypeCd: this.key.equipmentTypeCd,
        plantCd: this.key.plantCd,
        checkTypeItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartCd: 'CIPC000001', // 설비부품별 점검
        checkItemPartName: props.row.checkItemPartName,
        checkItemCycleCd: null,
        cbmTypeCd: null,
        cbmFlag: 'N',
        checkItemName: '',
        checkItemMethod: '',
        checkStandard: '',
        sortOrder: Number(props.row.sortOrder + 1),
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
    },
    cancelMst() {
      this.hiddenMstCd = '';
      this.prompt = false;
    },
    setMst() {
      this.prompt = false;
      this.grid2.data.push({
        equipmentTypeCd: this.key.equipmentTypeCd,
        plantCd: this.key.plantCd,
        checkTypeItemId: uid(),
        equipmentCheckTypeCd: null,
        checkItemPartCd: 'CIPC000001', // 설비부품별 점검
        checkItemPartName: this.hiddenMstCd,
        cbmTypeCd: null,
        cbmFlag: 'N',
        checkItemCycleCd: null,
        checkItemName: '',
        checkItemMethod: '',
        checkStandard: '',
        sortOrder: this.grid2.data.length == 0 ? 1 : (this.grid2.data.length + 1) * 10,
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        editFlag: 'C',
      })
      this.hiddenMstCd = '';
    },
  }
};
</script>
